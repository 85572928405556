import React from 'react';
import { store } from '../../store';
import {
  setGamePin,
  setMultiplayerSessionId,
  setPlaylist,
  pushMessageFromPlayer,
  pushMessageToPlayer,
  setActivity,
} from '../redux';
import { playerClientReady } from '../../player/features/puzzle-player/redux';
import {
  controllerClientReady,
  gameStarted,
  gameEnded,
  setControllerCID,
  gamePaused,
  setGameJoinError,
  resetGame,
  endCountdown,
  setNumberOfControllersWhenGameStarted,
} from '../../controller/features/puzzle-controller/redux';
import PlayerClient from '../lib/game-service-clients/player-client';
import ControllerClient from '../lib/game-service-clients/controller-client';
import {
  addParticipant,
  incrementArrayStats,
  incrementFractionsStats,
  removeParticipant,
  updateVisibilityInPlayer,
} from '../../player/features/game-mode/chest/participants/redux';
import * as Sentry from '@sentry/browser';
import { GameStartedBroadcastPayload } from '../typedefs/types';
import { addCollectGems } from '../../player/features/game-mode/chest/components/chest-game-mode-component/redux';
import { ActivityId } from '@puzzles/core/types';

// const dispatch = useDispatch();

// Player Use Case:
// To construct/init: Happens here, nothing to worry about
// To get ref: const { playerClient } = useContext(GameServiceContext);
// To Send: playerClient.broadcastToControllers({})  - retry if returns false
// To get notified: the callbacks write to the store

// Controller Use Case:
// To construct: Happens here
// To get ref: const { controllerClient } = useContext(GameServiceContext);
// To init: Get ref and then await controllerClient.init(gamePin, nickname)
//   these might also be held in stateHooks, so you want to useEffect
// To Send: controllerClient.sendToPlayer({}) - retry if returns false
// To get notified: the callbacks write to the store

const playerGameStartedCallback = (data: any) => {
  // TODO: define shared message schema here and process
  // w discriminated union
  console.log('Player game Has Started Message', data);

  //this is called when the Player received a Pin from the service and is ready to wait for Controller connections
  //(but despite the name of this function, the gameplay will not start until some Controllers connect and the Start button is pressed)
  const pin: string = data;

  //create a session id that will identify this multiplayer game session in all the analytic events of all the participant controllers
  const multiplayerSessionId: string = Date.now() + '_' + pin;

  store.dispatch(setMultiplayerSessionId(multiplayerSessionId)); //set multiplayerSessionId in the Player store
  store.dispatch(setGamePin(data));
};
const playerJoinCallback = (data: any) => {
  console.log('Player Received Join Message', data);
  const isPuzzle = JSON.parse(data.content).puzzle;
  if (isPuzzle) {
    const avatar = JSON.parse(data.content).avatar;
    const visible = JSON.parse(data.content).visibleByDefault;
    store.dispatch(
      addParticipant({
        cid: data.cid,
        name: data.name,
        avatar,
        visibleInPlayer: visible,
        arraysCreated: 0,
        levelsCompleted: 0,
      })
    );
  } else {
    console.warn('Controller is not a puzzle. Cannot join game.');
    Sentry.captureMessage('Controller is not a puzzle. Cannot join game.');
  }
};

const playerLeftCallback = (data: any) => {
  console.log('Player Received Leave Message', data);
  store.dispatch(removeParticipant(data.cid));
};

const playerMessageCallback = (data: any) => {
  // TODO: define shared message schema here and process
  // w discriminated union
  //console.log('Player Received Message', data);

  if (data.content.type === 'CollectGems') {
    store.dispatch(addCollectGems(data));
  }
  if (data.content.type === 'ToggleVisibilityInPlayer') {
    const state = store.getState();
    if (!state.player.features.puzzle.ended) store.dispatch(updateVisibilityInPlayer(data));
  }
  if (data.content.type === 'UpdateArrayStats') {
    store.dispatch(incrementArrayStats(data));
  }

  if (data.content.type === 'UpdateFractionsStats') {
    store.dispatch(incrementFractionsStats(data));
  }

  store.dispatch(pushMessageToPlayer(data));
};
const playerInitCompletedCallback = (data: any) => {
  // TODO: define shared message schema here and process
  // w discriminated union
  console.log('Player Init Completed Message', data);
  store.dispatch(playerClientReady());
};
const controllerMessageCallback = (data: any) => {
  // TODO: define shared message schema here and process
  // w discriminated union
  console.log('Controller Received Message', data);

  if (data.content.activity) {
    store.dispatch(setActivity(data.content.activity));
  }

  if (data.content.playlist) {
    store.dispatch(setPlaylist(data.content.playlist));
  }

  if (data.content.gameStarted == true) {
    store.dispatch(gameStarted());
  }

  if (data.content.type === 'ControllerJoinedGameMessage') {
    store.dispatch(setMultiplayerSessionId(data.content.multiplayerSessionId)); //set multiplayerSessionId in the Controller store
    store.dispatch(setControllerCID(data.content.cid));
  }

  if (data.content.type === 'GameStartedBroadcastMessage') {
    const gameStartedBroadcastContent: GameStartedBroadcastPayload = JSON.parse(data.content.payload);
    store.dispatch(setNumberOfControllersWhenGameStarted(gameStartedBroadcastContent.numberOfPlayers));
    store.dispatch(gameStarted());
  }

  if (data.content.type === 'GameEndCountdownBroadcast') {
    store.dispatch(endCountdown());
  }

  if (data.content.type === 'GameEndedBroadcast') {
    store.dispatch(gameEnded());
  }
  if (data.content.type === 'GamePauseBroadcast') {
    store.dispatch(gamePaused(true));
  }
  if (data.content.type === 'GamePlayBroadcast') {
    store.dispatch(gamePaused(false));
  }
  store.dispatch(pushMessageFromPlayer(data));
};
const controllerInitCompletedCallback = (data: any) => {
  // TODO: define shared message schema here and process
  // w discriminated union
  console.log('Controller Init Completed Message', data);
  store.dispatch(controllerClientReady(true));
};

const handleUserInputError = (error: any) => {
  console.log(error);
  switch (error) {
    case 'Duplicate name':
      store.dispatch(setGameJoinError('Sorry, that nickname is taken.'));
      break;
    case 'locked':
      store.dispatch(setGameJoinError('Sorry, this game is full!'));
      break;
    case 'invalidpin':
      store.dispatch(setGameJoinError("We didn't recognize that game PIN. Please check and try again."));
      break;
  }
};

const controllerStatusCallback = (data: any) => {
  store.dispatch(setGameJoinError(''));
  if (data?.content?.status) {
    switch (data.content.status) {
      case 'LOCKED':
        handleUserInputError('locked');
        break;
    }
    switch (data.content.status) {
      case 'MISSING':
        console.log('Game ended by game server');
        store.dispatch(resetGame());
        break;
    }
  }
};

const controllerJoinErrorCallback = (data: any) => {
  // Clear previous errors
  console.log('controllerJoinErrorCallback:' + data); //if it logs in successfull, then it will trigger this callback with 'false' as parameter, meaning there's no error and everything is OK
  store.dispatch(setGameJoinError(''));
  if (data?.content?.error) {
    switch (data.content.error) {
      case 'USER_INPUT':
        handleUserInputError(data.content.description);
        break;
    }
    switch (data.content.error) {
      case 'GAME_DOES_NOT_EXIST':
        handleUserInputError(data.content.description);
        break;
    }
  }
};

export const gameServiceClients = {
  playerClient: new PlayerClient(
    playerJoinCallback,
    playerMessageCallback,
    playerGameStartedCallback,
    playerInitCompletedCallback,
    playerLeftCallback
  ),
  controllerClient: new ControllerClient(
    controllerMessageCallback,
    controllerInitCompletedCallback,
    controllerJoinErrorCallback,
    controllerStatusCallback
  ),
};

export const GameServiceContext = React.createContext(gameServiceClients);

/**
 * Returns the associated activity id for a given activity name.
 * This activityName corresponds to the `ActivityInfo.name` value.
 * @param activityName The name of the activity (e.g. "multiplication", "fractions", etc.)
 */
export const getActivityIdFromActivityName = (activityName: string): ActivityId => {
  if (activityName === 'multiplication') {
    return ActivityId.ArrayCrew;
  }
  if (activityName === 'fractions') {
    return ActivityId.DuckFractions;
  }
  throw new Error(`Unknown activity name: ${activityName}`);
};

/**
 * Returns the associated activity id for the current activity in the common store.
 * This is a convenience function that calls `getActivityIdFromActivityName` with the current activity name in the common store.
 * ```ts
 * // Equivalent to:
 * getActivityIdFromActivityName(state.common.activity);
 * ```
 */
export const getActivityIdFromCommonStore = (): ActivityId => {
  const state = store.getState();
  return getActivityIdFromActivityName(state.common.activity);
};
