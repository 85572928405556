import { combineReducers } from '@reduxjs/toolkit';
import arrayPuzzleQuizReducer from '../components/quiz/redux';
import arrayPuzzleActivityReducer from '../components/activity/redux';

// I've combined these into a chest "contorller" reducer. It would be good to have standalone versions of these, but currently
// they are coupled closely to the functionality of the chest.
export const arrayControllerReducer = combineReducers({
  quiz: arrayPuzzleQuizReducer,
  activity: arrayPuzzleActivityReducer,
});
