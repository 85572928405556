import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import commonReducer from '../common/redux';
import { playerRootReducer } from '../player/store/player-root-reducer';
import { controllerRootReducer } from '../controller/store/controller-root-reducer';

// This is what kahoot actually does
// const createRootReducer = () =>
//     combineReducers({
//     controller: controllerReducers,
// });
//
// const rootReducer = createRootReducer();
//
// export default rootReducer;

// We do this because we don't have multiple react applications , they all share app.tsx and so a default
// root reducer... for now. But on a component level this shouldn't matter anyway.

const persistConfig = {
  key: 'controller_persisted',
  storage,
};

const persistedReducer = persistReducer(persistConfig, controllerRootReducer);

const rootReducer = combineReducers({
  common: commonReducer,
  controller: persistedReducer,
  player: playerRootReducer,
});

export default rootReducer;
