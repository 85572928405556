import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store/store';

export type ArrayPuzzleActivityState = {
  levelsCompleted: number;
  levelName: string;
  levelIndexInPlaylist: number;
  hudDimActive: boolean;
  lastCompletedLevelName: string;
  lastCompletedLevelIndexInPlaylist: number;
  skips: number;
};

const defaultArrayPuzzleActivityState: ArrayPuzzleActivityState = {
  levelsCompleted: 0,
  levelName: '',
  levelIndexInPlaylist: 0,
  hudDimActive: false,
  lastCompletedLevelName: '',
  lastCompletedLevelIndexInPlaylist: -1,
  skips: 0,
};

export const arrayPuzzleActivitySlice = createSlice({
  name: 'array',
  initialState: defaultArrayPuzzleActivityState,
  reducers: {
    resetState: () => defaultArrayPuzzleActivityState,
    setLevelsCompleted: (state, action: PayloadAction<number>) => {
      state.lastCompletedLevelIndexInPlaylist = state.levelIndexInPlaylist;
      state.lastCompletedLevelName = state.levelName;
      state.levelsCompleted = action.payload;
    },
    setLevelName: (state, action: PayloadAction<string>) => {
      state.levelName = action.payload;
    },
    setHudDimActive: (state, action: PayloadAction<boolean>) => {
      state.hudDimActive = action.payload;
    },
    setLastCompletedLevelName: (state, action: PayloadAction<string>) => {
      state.lastCompletedLevelName = action.payload;
    },
    setLevelIndexInPlaylist: (state, action: PayloadAction<number>) => {
      state.levelIndexInPlaylist = action.payload;
    },
    setLastCompletedLevelIndexInPlaylist: (state, action: PayloadAction<number>) => {
      state.lastCompletedLevelIndexInPlaylist = action.payload;
    },
    setSkips: (state, action: PayloadAction<number>) => {
      state.skips = action.payload;
    },
    incrementSkips: (state) => {
      state.skips++;
    },
  },
});

export const {
  resetState,
  setLevelsCompleted,
  setLevelName,
  setHudDimActive,
  setLevelIndexInPlaylist,
  setLastCompletedLevelName,
  setLastCompletedLevelIndexInPlaylist,
  setSkips,
  incrementSkips,
} = arrayPuzzleActivitySlice.actions;

export const selectHudDimActive = (state: RootState): boolean => state.controller.features.array.activity.hudDimActive;
export const selectControllerLevelsCompleted = (state: RootState): number =>
  state.controller.features.array.activity.levelsCompleted;
export const selectControllerLevelName = (state: RootState): string =>
  state.controller.features.array.activity.levelName;
export const selectControllerLastCompletedLevelName = (state: RootState): string =>
  state.controller.features.array.activity.lastCompletedLevelName;
export const selectControllerLevelIndexInPlaylist = (state: RootState): number =>
  state.controller.features.array.activity.levelIndexInPlaylist;
export const selectControllerLastCompletedLevelIndexInPlaylist = (state: RootState): number =>
  state.controller.features.array.activity.lastCompletedLevelIndexInPlaylist;
export const selectControllerSkips = (state: RootState): number => state.controller.features.array.activity.skips;

export default arrayPuzzleActivitySlice.reducer;
