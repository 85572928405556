import { combineReducers } from 'redux';
import puzzleControllerReducer from '../puzzle-controller/redux';
import { chestControllerReducer } from '../game-mode/chest/redux';
import { arrayControllerReducer } from '../activity/array-unity/redux';
import badgeControllerReducer from '../../components/badge/redux';

export const featuresReducer = combineReducers({
  puzzle: puzzleControllerReducer,
  chest: chestControllerReducer,
  array: arrayControllerReducer,
  badge: badgeControllerReducer,
});
