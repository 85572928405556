import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';

export interface PuzzlePlayerState {
  clientReady: boolean;
  started: boolean;
  endCountdown: boolean;
  ended: boolean;
  endGameSource: string;
  showNavigationWarning: boolean;
  lobbyLoaded: boolean;
}

const defaultPuzzlePlayerState: PuzzlePlayerState = {
  clientReady: false,
  started: false,
  endCountdown: false,
  ended: false,
  endGameSource: '',
  showNavigationWarning: false,
  lobbyLoaded: false,
};

export const puzzlePlayerSlice = createSlice({
  name: 'puzzle',
  initialState: defaultPuzzlePlayerState,
  reducers: {
    resetState: () => defaultPuzzlePlayerState,
    playerClientReady: (state) => {
      state.clientReady = true;
    },
    startGame: (state) => {
      state.started = true;
    },
    endCountdown: (state) => {
      state.endCountdown = true;
    },
    endGame: (state) => {
      state.ended = true;
    },
    setEndGameSource: (state, action: PayloadAction<string>) => {
      state.endGameSource = action.payload;
    },
    setShowNavigationWarning: (state, action: PayloadAction<boolean>) => {
      state.showNavigationWarning = action.payload;
    },
    setLobbyLoaded: (state, action: PayloadAction<boolean>) => {
      state.lobbyLoaded = action.payload;
    },
  },
});

export const {
  resetState,
  playerClientReady,
  startGame,
  endCountdown,
  endGame,
  setEndGameSource,
  setShowNavigationWarning,
  setLobbyLoaded,
} = puzzlePlayerSlice.actions;

// select from player store
export const selectPlayerGameStarted = (state: RootState): boolean => state.player.features.puzzle.started;
export const selectPlayerGameEnded = (state: RootState): boolean => state.player.features.puzzle.ended;
export const selectPlayerEndCountdown = (state: RootState): boolean => state.player.features.puzzle.endCountdown;
export const selectPlayerClientReady = (state: RootState): boolean => state.player.features.puzzle.clientReady;
export const selectShowNavigationWarning = (state: RootState): boolean =>
  state.player.features.puzzle.showNavigationWarning;
export const selectLobbyLoaded = (state: RootState): boolean => state.player.features.puzzle.lobbyLoaded;
export default puzzlePlayerSlice.reducer;
