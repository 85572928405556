import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageToPlayer } from '../../../../../../../common/typedefs/types';
import { RootState } from '../../../../../../../store/store';

type GemCollected = {
  nickname: string;
  gemType: number;
  gemId: number;
};

export type ChestPlayerGameModeState = {
  gemsCollected: GemCollected[];
};

const defaultChestPlayerGameModeState: ChestPlayerGameModeState = {
  gemsCollected: [],
};

export const chestPlayerGameModeSlice = createSlice({
  name: 'gameMode',
  initialState: defaultChestPlayerGameModeState,
  reducers: {
    resetState: () => defaultChestPlayerGameModeState,
    addCollectGems: (state, action: PayloadAction<MessageToPlayer>) => {
      action.payload.content.payload.gemTypes.forEach((gemType: number, index: number) => {
        state.gemsCollected.push({
          nickname: action.payload.content.payload.nickname,
          gemType: gemType,
          gemId: action.payload.timetrack + index,
        });
      });
    },
    removeGems: (state, action: PayloadAction<Number[]>) => {
      for (let i = state.gemsCollected.length - 1; i >= 0; --i) {
        if (action.payload.includes(state.gemsCollected[i].gemId)) state.gemsCollected.splice(i, 1);
      }
    },
  },
});

export const { resetState, addCollectGems, removeGems } = chestPlayerGameModeSlice.actions;

export const selectPlayerGemsCollected = (state: RootState): GemCollected[] =>
  state.player.features.chest.gameMode.gemsCollected;
export default chestPlayerGameModeSlice.reducer;
