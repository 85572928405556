import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { MessageFromPlayer, MessageToPlayer } from '../typedefs/types';

export type LoadingStatuses = 'idle' | 'loading' | 'failed';

export type CommonState = {
  loadingStatus: LoadingStatuses;
  pin: number | null;
  multiplayerSessionId: string | null;
  playlist: string;
  activity: string;
  messagesToPlayer: Array<MessageToPlayer>;
  messagesFromPlayer: Array<MessageFromPlayer>;
};

const defaultCommonState: CommonState = {
  loadingStatus: 'idle',
  pin: null,
  multiplayerSessionId: null,
  playlist: '',
  activity: '',
  messagesFromPlayer: [],
  messagesToPlayer: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: defaultCommonState,
  reducers: {
    resetState: () => defaultCommonState,
    setGamePin: (state, action: PayloadAction<number>) => {
      state.pin = action.payload;
    },
    setMultiplayerSessionId: (state, action: PayloadAction<string>) => {
      state.multiplayerSessionId = action.payload;
    },
    setPlaylist: (state, action: PayloadAction<string>) => {
      state.playlist = action.payload;
    },
    setActivity: (state, action: PayloadAction<string>) => {
      state.activity = action.payload;
    },
    pushMessageToPlayer: (state, action: PayloadAction<MessageToPlayer>) => {
      state.messagesToPlayer.push(action.payload);
    },
    pushMessageFromPlayer: (state, action: PayloadAction<MessageFromPlayer>) => {
      state.messagesFromPlayer.push(action.payload);
    },
  },
});

export const {
  resetState,
  setGamePin,
  setMultiplayerSessionId,
  setPlaylist,
  setActivity,
  pushMessageToPlayer,
  pushMessageFromPlayer,
} = commonSlice.actions;

export const selectGamePin = (state: RootState): number | null => state.common.pin;
export const selectMultiplayerSessionId = (state: RootState): string | null => state.common.multiplayerSessionId;
export const selectGamePlaylist = (state: RootState): string => state.common.playlist;
export const selectGameActivity = (state: RootState): string => state.common.activity;
export const selectMessagesToPlayer = (state: RootState): Array<MessageToPlayer> => state.common.messagesToPlayer;
export const selectMessagesFromPlayer = (state: RootState): Array<MessageFromPlayer> => state.common.messagesFromPlayer;
export default commonSlice.reducer;
