import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../store/store';

export type ChestParticipant = {
  cid: string;
  name: string;
  avatar: string | undefined;
  visibleInPlayer: boolean;
  arraysCreated: number;
  levelsCompleted: number;
};

type ChestParticipantAvatar = {
  cid: string;
  avatar: string | undefined;
};

type ChestParticipantVisibility = {
  timetrack: number;
  content: {
    payload: {
      cid: string;
      visibleInPlayer: boolean;
    };
  };
};

type ChestParticipantArrayStats = {
  content: {
    payload: {
      cid: string;
      arraysCreated: number;
      levelsCompleted: number;
    };
  };
};

type ChestParticipantFractionsStats = {
  content: {
    payload: {
      cid: string;
      levelsCompleted: number;
    };
  };
};

export type ChestParticipantsToRemove = {
  cid: string;
};

type ChestParticipantState = {
  participants: ChestParticipant[];
  participantsToRemove: ChestParticipantsToRemove[];
};

const defaultChestParticipantState: ChestParticipantState = {
  participants: [] as ChestParticipant[],
  participantsToRemove: [] as ChestParticipantsToRemove[],
};

export const chestParticipantsSlice = createSlice({
  name: 'chestParticipants',
  initialState: defaultChestParticipantState,
  reducers: {
    resetState: () => defaultChestParticipantState,
    addParticipant: (state, action: PayloadAction<ChestParticipant>) => {
      state.participants.push(action.payload);
    },
    removeParticipant: (state, action: PayloadAction<string>) => {
      const index = state.participants.findIndex((participant) => participant.cid === action.payload);
      if (state.participants[index]) state.participants.splice(index, 1);
      state.participantsToRemove.push({ cid: action.payload });
    },
    participantRemovedFromGame: (state, action: PayloadAction<string>) => {
      const index = state.participantsToRemove.findIndex((participant) => participant.cid === action.payload);
      if (state.participantsToRemove[index]) state.participantsToRemove.splice(index, 1);
    },
    updateParticipantAvatar: (state, action: PayloadAction<ChestParticipantAvatar>) => {
      const index = state.participants.findIndex((participant) => participant.cid === action.payload.cid);
      if (state.participants[index]) state.participants[index].avatar = action.payload.avatar;
    },
    updateVisibilityInPlayer: (state, action: PayloadAction<ChestParticipantVisibility>) => {
      const index = state.participants.findIndex(
        (participant) => participant.cid === action.payload.content.payload.cid
      );
      if (state.participants[index])
        state.participants[index].visibleInPlayer = action.payload.content.payload.visibleInPlayer;
    },
    incrementArrayStats: (state, action: PayloadAction<ChestParticipantArrayStats>) => {
      const index = state.participants.findIndex(
        (participant) => participant.cid === action.payload.content.payload.cid
      );
      if (state.participants[index] && action?.payload?.content?.payload?.arraysCreated) {
        state.participants[index].arraysCreated += action.payload.content.payload.arraysCreated;
        state.participants[index].levelsCompleted++;
      }
    },
    incrementFractionsStats: (state, action: PayloadAction<ChestParticipantFractionsStats>) => {
      const index = state.participants.findIndex(
        (participant) => participant.cid === action.payload.content.payload.cid
      );
      if (state.participants[index]) {
        state.participants[index].levelsCompleted++;
      }
    },
  },
});

export const {
  resetState,
  addParticipant,
  removeParticipant,
  updateParticipantAvatar,
  updateVisibilityInPlayer,
  participantRemovedFromGame,
  incrementArrayStats,
  incrementFractionsStats,
} = chestParticipantsSlice.actions;
export const selectParticipants = (state: RootState): Array<ChestParticipant> =>
  state.player.features.chest.participants.participants;

export const selectRemoveParticipantsQueue = (state: RootState): Array<ChestParticipantsToRemove> =>
  state.player.features.chest.participants.participantsToRemove;

export default chestParticipantsSlice.reducer;
