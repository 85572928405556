import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';

export type PuzzleControllerState = {
  cid: string;
  nickname: string | null;
  avatar: string | undefined;
  gamePin: string | null;
  gameJoined: boolean;
  gameJoinError: string;
  gamePaused: boolean;
  gameStarted: boolean;
  numberOfControllersWhenGameStarted: number;
  countdownEnded: boolean;
  gameEnded: boolean;
  clientReady: boolean;
  reset: boolean;
};

const defaultPuzzleController: PuzzleControllerState = {
  cid: '',
  nickname: null,
  avatar: undefined,
  gamePin: '',
  gameJoined: false,
  gameJoinError: '',
  gamePaused: false,
  gameStarted: false,
  numberOfControllersWhenGameStarted: 0,
  countdownEnded: false,
  gameEnded: false,
  clientReady: false,
  reset: false,
};

export const puzzleControllerSlice = createSlice({
  name: 'puzzle',
  initialState: defaultPuzzleController,
  reducers: {
    resetState: () => defaultPuzzleController,
    setGameJoinError: (state, action: PayloadAction<string | ''>) => {
      state.gameJoinError = action.payload;
    },
    setGameJoined: (state, action: PayloadAction<boolean | false>) => {
      state.gameJoined = action.payload;
    },
    setControllerNickname: (state, action: PayloadAction<string | null>) => {
      state.nickname = action.payload;
    },
    setControllerGamePin: (state, action: PayloadAction<string | null>) => {
      state.gamePin = action.payload;
    },
    setControllerAvatar: (state, action: PayloadAction<string | undefined>) => {
      state.avatar = action.payload;
    },
    setControllerCID: (state, action: PayloadAction<string>) => {
      state.cid = action.payload;
    },
    controllerClientReady: (state, action: PayloadAction<boolean | true>) => {
      state.clientReady = action.payload;
    },
    gameStarted: (state) => {
      state.gameStarted = true;
    },
    setNumberOfControllersWhenGameStarted: (state, action: PayloadAction<number>) => {
      state.numberOfControllersWhenGameStarted = action.payload;
    },
    endCountdown: (state) => {
      state.countdownEnded = true;
    },
    gameEnded: (state) => {
      state.gameEnded = true;
    },
    gamePaused: (state, action: PayloadAction<boolean | false>) => {
      state.gamePaused = action.payload;
    },
    resetGame: (state) => {
      state.reset = true;
    },
  },
});

export const {
  setGameJoinError,
  setGameJoined,
  setControllerCID,
  setControllerGamePin,
  setControllerNickname,
  setControllerAvatar,
  controllerClientReady,
  gameStarted,
  setNumberOfControllersWhenGameStarted,
  endCountdown,
  gameEnded,
  gamePaused,
  resetState,
  resetGame,
} = puzzleControllerSlice.actions;

export const selectGameJoinError = (state: RootState): string => state.controller.features.puzzle.gameJoinError;
export const selectGameJoined = (state: RootState): boolean => state.controller.features.puzzle.gameJoined;
export const selectControllerCID = (state: RootState): string => state.controller.features.puzzle.cid;
export const selectControllerGamePin = (state: RootState): string | null => state.controller.features.puzzle.gamePin;
export const selectControllerNickname = (state: RootState): string | null => state.controller.features.puzzle.nickname;
export const selectControllerAvatar = (state: RootState): string | undefined => state.controller.features.puzzle.avatar;
export const selectControllerClientReady = (state: RootState): boolean => state.controller.features.puzzle.clientReady;
export const selectControllerGameStarted = (state: RootState): boolean => state.controller.features.puzzle.gameStarted;
export const selectNumberOfControllersWhenGameStarted = (state: RootState): number =>
  state.controller.features.puzzle.numberOfControllersWhenGameStarted;
export const selectControllerEndCountdown = (state: RootState): boolean =>
  state.controller.features.puzzle.countdownEnded;
export const selectControllerGameEnded = (state: RootState): boolean => state.controller.features.puzzle.gameEnded;
export const selectControllerGamePaused = (state: RootState): boolean => state.controller.features.puzzle.gamePaused;
export const selectResetGame = (state: RootState): boolean => state.controller.features.puzzle.reset;

export default puzzleControllerSlice.reducer;
