import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store/store';

export type ArrayPuzzleQuizState = {
  quizPanelActive: boolean;
  quizCreationData: ArrayPuzzleQuizCreationData | null;
  correctQuizzes: number;
  quizAttempts: number;
};

export type ArrayPuzzleQuizQuestionData = {
  question: string;
  correctAnswer: string;
  wrongAnswers: string[];
};

export type ArrayPuzzleQuizCreationData = {
  levelNumber: number;
  questionData: ArrayPuzzleQuizQuestionData;
  pngData: string;
};

const defaultArrayPuzzleQuizState: ArrayPuzzleQuizState = {
  quizPanelActive: false,
  quizCreationData: null,
  correctQuizzes: 0,
  quizAttempts: 0,
};

export const arrayPuzzleQuizSlice = createSlice({
  name: 'quiz',
  initialState: defaultArrayPuzzleQuizState,
  reducers: {
    resetState: () => defaultArrayPuzzleQuizState,
    setQuizPanelActive: (state, action: PayloadAction<boolean | false>) => {
      state.quizPanelActive = action.payload;
    },
    setQuizCreationData: (state, action: PayloadAction<ArrayPuzzleQuizCreationData>) => {
      state.quizCreationData = action.payload;
    },
    setCorrectQuizzes: (state, action: PayloadAction<number>) => {
      state.correctQuizzes = action.payload;
    },
    setQuizAttempts: (state, action: PayloadAction<number>) => {
      state.quizAttempts = action.payload;
    },
    incrementCorrectQuizzes: (state) => {
      state.correctQuizzes++;
    },
  },
});

export const {
  setQuizPanelActive,
  setQuizCreationData,
  resetState,
  setCorrectQuizzes,
  setQuizAttempts,
  incrementCorrectQuizzes,
} = arrayPuzzleQuizSlice.actions;

// Select from controller store
export const selectQuizPanelActive = (state: RootState): boolean =>
  state.controller.features.array.quiz.quizPanelActive;
export const selectControllerQuizCreationData = (state: RootState): ArrayPuzzleQuizCreationData | null =>
  state.controller.features.array.quiz.quizCreationData;
export const selectControllerCorrectQuizzes = (state: RootState): number =>
  state.controller.features.array.quiz.correctQuizzes;
export const selectQuizAttempts = (state: RootState): number => state.controller.features.array.quiz.quizAttempts;

export default arrayPuzzleQuizSlice.reducer;
