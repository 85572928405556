import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store/store';

export type ChestInventoryState = {
  inventory: PuzzleControllerInventoryItem[];
};

export type PuzzleControllerInventoryItem = {
  type: number;
};

const defaultChestInventoryState: ChestInventoryState = {
  inventory: [],
};

export const chestInventorySlice = createSlice({
  name: 'chestInventory',
  initialState: defaultChestInventoryState,
  reducers: {
    resetState: () => defaultChestInventoryState,
    addControllerInventoryItem: (state, action: PayloadAction<number>) => {
      if (state.inventory.length >= 12) {
        //console.log('Maximum inventory size reached: 12');
        return;
      }
      state.inventory.push({
        type: action.payload,
      });
    },
    removeControllerInventoryItems: (state, action: PayloadAction<number>) => {
      state.inventory.splice(0, action.payload);
    },
  },
});

export const { addControllerInventoryItem, removeControllerInventoryItems, resetState } = chestInventorySlice.actions;

// Select from controller store
export const selectControllerInventory = (state: RootState): PuzzleControllerInventoryItem[] =>
  state.controller.features.chest.inventory.inventory;

export default chestInventorySlice.reducer;
