import { joinGame } from './helpers.js';
import { CometD } from 'cometd';
import TimeSyncExtension from 'cometd/TimeSyncExtension'; //useful to calculate network lag
import AckExtension from 'cometd/AckExtension'; //This client-side extension enables the client to acknowledge to the server the messages that the client has received. The messages will arrive with total order

export default class ControllerClient {
  // TODO: use the new private field notation
  // #messageCallback: any; etc
  constructor(messageCallback, initCompleteCallback, joinErrorCallback, statusCallback) {
    this.gamePin = null;
    this.sessionID = null;
    this.cometd = null;
    this.ready = false;
    this.cid = null;
    this.avatar = null;
    this.visibleByDefault = false;
    this.messageCallback = messageCallback;
    this.initCompleteCallback = initCompleteCallback;
    this.joinErrorCallback = joinErrorCallback;
    this.statusCallback = statusCallback;
    //console.log('Client Ctor called');
  }

  async init(gamePin, nickname, avatar, cid = '', visibleByDefault = false) {
    // if (this.cometd != null) {
    //   console.log('Cannot re-init an existing controller client instance');
    //   return;
    // }

    if (this.cometd) {
      this.cometd.disconnect();
    }
    this.cometd = new CometD();
    this.gamePin = gamePin;
    this.cid = cid;
    this.avatar = avatar;
    this.nickname = nickname;
    this.visibleByDefault = visibleByDefault;

    // get session from gamePin
    this.sessionID = await joinGame(this.gamePin);

    if (this.sessionID === 404) {
      this.joinErrorCallback({
        content: {
          error: 'GAME_DOES_NOT_EXIST',
          description: 'invalidpin',
        },
      });
      return;
    }

    // init cometd game from gamePin and sessionID
    console.log(
      'call init with url:' +
        `https://${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/cometd/${this.gamePin}/${this.sessionID}`
    );

    //try using ack and timesync like here: https://github.com/mobitroll/kahoot-frontend/blob/db80280c53e12e1c1de5cc749fca72dd0e43d41d/src/player/network/redux/cometd.ts
    this.cometd.registerExtension('timesync', new TimeSyncExtension());
    this.cometd.registerExtension('ack', new AckExtension());

    let debugLevel = '';
    if (import.meta.env.VITE_DEBUG_MODE_ENABLED == 'true') debugLevel = 'debug';

    this.cometd.init({
      url: `https://${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/cometd/${this.gamePin}/${this.sessionID}`,
      logLevel: debugLevel,
      maxNetworkDelay: 40_000,
    });

    const data = {
      type: 'login',
      gameid: this.gamePin.toString(),
      host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
      content: JSON.stringify({
        puzzle: true,
        avatar: avatar,
        visibleByDefault: visibleByDefault,
        device: {
          userAgent: navigator.userAgent,
        },
      }),
    };

    // Adjust data based on if it's a login or a relogin
    if (this.cid) {
      data.type = 'relogin';
      data.cid = this.cid;
    } else {
      data.name = nickname;
    }

    // join game
    setTimeout(() => {
      this.cometd.publish('/service/controller', data);
      console.log('try to join game, joinType:' + data.type + '   host:' + data.host);
    }, 1500);

    // Subscribe to the controller channel to check for join errors
    this.cometd.addListener('/service/controller', (message) => {
      if (message.data === undefined) {
        console.log('controllerEvent with no data:' + message);
        return;
      }

      console.log('controllerEvent:' + message.data.type + '   error:' + message.data.error);

      if (message.data.type === 'loginResponse' && message.data.error) {
        this.joinErrorCallback({
          content: message.data,
          timetrack: message.ext.timetrack,
        });
      } else {
        this.joinErrorCallback(false);
      }
    });

    // Subscribe to statuses
    this.cometd.addListener('/service/status', (message) => {
      console.log('statusEvent:' + message.data.type + '   message.data:' + JSON.stringify(message.data));

      if (message.data.type === 'status') {
        this.statusCallback({
          content: message.data,
          timetrack: message.ext.timetrack,
        });
      }
    });

    // subscribe to messages from player
    this.cometd.addListener('/service/player', (message) => {
      console.log('playerEvent:' + message.data.type + '   message.data:' + message.data.content);

      this.messageCallback({
        content: JSON.parse(message.data.content),
        timetrack: message.ext.timetrack,
      });
    });

    this.ready = true;
    this.initCompleteCallback();
  }

  disconnect(cid) {
    if (!this.cometd) return;
    const data = {
      cid: cid,
      gameId: this.gamePin,
      cometSessionId: this.cometd.getClientId(),
    };
    console.log(data);
    const headers = {
      type: 'application/json',
    };
    const blob = new Blob([JSON.stringify(data)], headers);
    const url = `https://${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/cometd/${this.gamePin}/${
      this.sessionID
    }/api/disconnect_session`;

    navigator.sendBeacon(url, blob);

    return true;
  }

  sendToPlayer(payloadJSON) {
    if (this.ready) {
      this.cometd.publish('/service/controller', {
        id: 0,
        type: 'message',
        gameid: this.gamePin,
        host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
        content: payloadJSON,
      });
      return true;
    }
    return false;
  }
}
