import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';

export type BadgeState = {
  badgeActive: boolean;
};

const defaultBadgeState: BadgeState = {
  badgeActive: true,
};

export const badgeSlice = createSlice({
  name: 'badge',
  initialState: defaultBadgeState,
  reducers: {
    resetState: () => defaultBadgeState,
    setBadgeActive: (state, action: PayloadAction<boolean | false>) => {
      state.badgeActive = action.payload;
    },
  },
});

export const { setBadgeActive, resetState } = badgeSlice.actions;

export const selectBadgeActive = (state: RootState): boolean => state.controller.features.badge.badgeActive;

export default badgeSlice.reducer;
