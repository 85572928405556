import { createPin } from './helpers.js';
import { CometD } from 'cometd';
import TimeSyncExtension from 'cometd/TimeSyncExtension'; //useful to calculate network lag
import AckExtension from 'cometd/AckExtension'; //This client-side extension enables the client to acknowledge to the server the messages that the client has received. The messages will arrive with total order

export default class PlayerClient {
  // TODO: use the new private field notation
  // #joinCallback: any; etc
  constructor(joinCallback, messageCallback, gameStartedCallback, initCompleteCallback, leftCallback) {
    this.gamePin = null;
    this.controllers = [];
    this.cometd = null;
    this.ready = false;
    this.sessionID = false;
    this.joinCallback = joinCallback;
    this.leftCallback = leftCallback;
    this.messageCallback = messageCallback;
    this.gameStartedCallback = gameStartedCallback;
    this.initCompleteCallback = initCompleteCallback;
    //console.log('Player Ctor called');
  }

  async init() {
    if (this.cometd != null) {
      console.log('Cannot re-init an existing player client instance');
      return;
    }
    this.cometd = new CometD();

    // get Pin for session
    const pinResp = await createPin();
    this.gamePin = await pinResp.text();
    this.sessionID = pinResp.headers.get('x-kahoot-session-token');
    const url = `https://${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/cometd/${this.gamePin}/${pinResp.headers.get(
      'x-kahoot-session-token'
    )}`;

    // init session in game service
    this.cometd.registerExtension('timesync', new TimeSyncExtension());
    this.cometd.registerExtension('ack', new AckExtension());

    let debugLevel = '';
    if (import.meta.env.VITE_DEBUG_MODE_ENABLED == 'true') debugLevel = 'debug';

    this.cometd.init({
      url,
      logLevel: debugLevel,
      maxNetworkDelay: 40_000,
    });

    // game service will send the first heartbeat, after which the player publishes
    // back to the game server that game has started.  this is unrelated to any
    // controllers joining.
    const listenOnce = this.cometd.addListener('/meta/connect', () => {
      this.cometd.publish('/service/player', {
        type: 'started',
        gameid: this.gamePin,
        host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
      });

      //console.log('GAME HAS STARTED');
      this.gameStartedCallback(this.gamePin);
      this.cometd.removeListener(listenOnce);
    });

    // listen for messages and join notifications from controller
    this.cometd.addListener(`/controller/${this.gamePin}`, (message) => {
      console.log('PlayerClient.init received message:' + message.data.type);
      switch (message.data.type) {
        case 'message':
          this.messageCallback({
            content: JSON.parse(message.data.content),
            cid: message.data.cid,
            timetrack: message.ext.timetrack,
          });
          break;
        case 'joined':
          this.joinCallback(message.data);
          break;
        case 'left':
          this.leftCallback(message.data);
          break;
      }
    });

    this.initCompleteCallback();
    this.ready = true;
  }

  disconnect() {
    if (!this.cometd) return;
    const data = {
      gameId: this.gamePin,
      cometSessionId: this.cometd.getClientId(),
      cid: '0',
    };
    console.log(data);
    const headers = {
      type: 'application/json',
    };
    const blob = new Blob([JSON.stringify(data)], headers);
    const url = `https://play.${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/cometd/${this.gamePin}/${
      this.sessionID
    }/api/disconnect_session`;

    navigator.sendBeacon(url, blob);

    this.cometd = null;
    this.ready = false;
    this.sessionID = false;
    return true;
  }

  broadcastToControllers(payloadJSON) {
    if (this.ready) {
      this.cometd.publish('/service/player', {
        gameid: this.gamePin,
        type: 'message',
        host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
        id: 0,
        content: payloadJSON,
      });
      return true;
    }
    return false;
  }

  lockGame() {
    this.cometd.publish('/service/player', {
      gameid: this.gamePin,
      type: 'lock',
      host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
    });
  }

  unlockGame() {
    this.cometd.publish('/service/player', {
      gameid: this.gamePin,
      type: 'unlock',
      host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
    });
  }

  broadcastToController(payloadJSON, cid) {
    if (this.ready) {
      this.cometd.publish('/service/player', {
        gameid: this.gamePin,
        type: 'message',
        host: `${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}`,
        id: 0,
        cid: cid,
        content: payloadJSON,
      });
      return true;
    }
    return false;
  }
}
