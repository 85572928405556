import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store/store';

export type ChestGameModeState = {
  sendGemPanelActive: boolean;
};

const defaultChestGameModeState: ChestGameModeState = {
  sendGemPanelActive: false,
};

export const chestGameModeSlice = createSlice({
  name: 'chestGameMode',
  initialState: defaultChestGameModeState,
  reducers: {
    resetState: () => defaultChestGameModeState,
    setSendGemPanelActive: (state, action: PayloadAction<boolean | false>) => {
      state.sendGemPanelActive = action.payload;
    },
  },
});

export const { setSendGemPanelActive, resetState } = chestGameModeSlice.actions;

export const selectSendGemPanelActive = (state: RootState): boolean =>
  state.controller.features.chest.gameMode.sendGemPanelActive;

export default chestGameModeSlice.reducer;
